import React, { useEffect, useRef, useState } from 'react'
import Chart from 'chart.js'
import _ from 'underscore'

export function DDChart(props) {
  const [chartApi, setChartApi] = useState(null)

  const ctx = useRef(null)

  const destroyApi = () => {
    if (chartApi) {
      chartApi.destroy()
    }
  }

  const getLabels = (data, labelKey) => {
    if (typeof props.getLabels === 'function') {
      return props.getLabels(data)
    } else if (Array.isArray(props.getLabels)) {
      return props.getLabels
    }
    const groupedData = _.groupBy(data, labelKey)
    return Object.keys(groupedData)
  }

  /**
   * if the input value is an array compute the output with props.accumulator if its defined.
   * @param value
   * @returns {*} cumulated value.
   * if the value is not an array and props.accumulator is not defined then input value is returned.
   */
  const accumulator = (value) => {
    if (typeof props.accumulator === 'function') {
      return props.accumulator(value)
    }
    if (Array.isArray(value)) {
      return value.length
    }
    return value
  }

  /**
   * group by labelKey input data and output an array of accumulated value.
   * @param data
   * @param labelKey
   * @param labels
   * @returns {unknown[]|*}
   */
  const getData = (data, labelKey, labels) => {
    if (typeof props.getData === 'function') {
      return props.getData(data, labelKey)
    } else if (Array.isArray(props.getData)) {
      return props.getData
    }
    const groupedData = _.groupBy(data, labelKey)
    if (labels) {
      return labels.map((label) => {
        const labelValue = groupedData[label] || []
        return accumulator(labelValue)
      })
    }
    return Object.values(groupedData).map(accumulator)
  }

  /**
   * Generate random colors for chart.
   * if props.getLabelColor is defined it is called.
   * If dataGroupKey is defined generate only one color for all labels
   * @param labels
   * @param dataGroupKey
   * @returns {*}
   */
  const getLabelColor = (labels, dataGroupKey) => {
    if (typeof props.getLabelColor === 'function') {
      return props.getLabelColor(labels, dataGroupKey)
    } else if (Array.isArray(props.getLabelColor)) {
      return props.getLabelColor
    }
    if (dataGroupKey) {
      const color = {
        r: Math.ceil(Math.random() * 255),
        g: Math.ceil(Math.random() * 255),
        b: Math.ceil(Math.random() * 255),
        a: 0.2
      }
      return labels.map((label) => color)
    }
    return labels.map((label) => {
      return {
        r: Math.ceil(Math.random() * 255),
        g: Math.ceil(Math.random() * 255),
        b: Math.ceil(Math.random() * 255),
        a: 0.2
      }
    })
  }

  /**
   * Based on color generated by getLabelColor
   * @param labelColor
   * @returns {*}
   */
  const getBackgroundColor = (labelColor) => {
    return labelColor
      ? labelColor.map((color) => {
          color.a = props.backgroundOpacity || 0.2
          return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
        })
      : labelColor
  }

  /**
   * Based on color generated by getLabelColor set border opacity
   * @param labelColor
   * @returns {*}
   */
  const getBorderColor = (labelColor) => {
    return labelColor
      ? labelColor.map((color) => {
          color.a = props.borderOpacity || 1
          return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
        })
      : labelColor
  }

  const initChart = () => {
    const options = Object.assign(
      {
        onClick: (event, context) => {
          if ((context || []).length === 0) return
          if (props.onClick) {
            props.onClick(event, context)
          }
        },
        onHover: function (e) {
          e.target.style.cursor = 'pointer'
          if (props.onHover) {
            props.onHover(e)
          }
        }
      },
      props.options || {}
    )
    const labelKey = Array.isArray(props.labelKey)
      ? props.labelKey
      : [props.labelKey]
    const initialData = props.data
    const labelKeyValue = labelKey[0]
    const labels = getLabels(initialData, labelKeyValue) // ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange']
    let datasets = []

    const getDataset = (dataGroup, dataGroupKey, labelColor) => {
      const data = getData(dataGroup, labelKeyValue, labels) // [12, 19, 3, 5, 2, 3]
      const backgroundColor = getBackgroundColor(labelColor)
      const borderColor = getBorderColor(labelColor)
      const dataset = {
        label: `${
          labelKey[1] && typeof labelKey[1] === 'string' ? labelKey[1] : ''
        }${
          typeof dataGroupKey === 'string' && dataGroupKey
            ? ' / ' + dataGroupKey
            : '#'
        }`,
        data: data,
        borderWidth: props.borderWidth || 1,
        fill: props.fill === true
      }
      if (backgroundColor) {
        dataset.backgroundColor = backgroundColor
      }
      if (borderColor) {
        dataset.borderColor = borderColor
      }
      return dataset
    }

    if (labelKey.length === 1) {
      const labelColor = getLabelColor(labels)
      const dataset = getDataset(initialData, labelKeyValue, labelColor)
      datasets.push(dataset)
    } else {
      const groupName = labelKey[1]
      const dataByGroupName = _.groupBy(initialData, groupName)
      const groupLabels = Object.keys(dataByGroupName)
      datasets = groupLabels.map((dataGroupKey) => {
        const labelColor = getLabelColor(labels, dataGroupKey)
        return getDataset(
          dataByGroupName[dataGroupKey],
          dataGroupKey,
          labelColor
        )
      })
    }
    const config = Object.assign(
      {
        type: props.type || 'bar',
        data: {
          labels: labels,
          datasets: datasets
        },
        options: options
      },
      props.config || {}
    )
    console.log('chartOptions', config)
    const chart = new Chart(ctx.current, config)
    setChartApi(chart)
    if (props.onChartReaddy) {
      props.onChartReaddy(chart)
    }
    return destroyApi
  }

  useEffect(initChart, [props.data])

  return <canvas ref={ctx} height={props.height} width={props.width} />
}
